<template>
  <span>
    <svg v-if="$attrs.provider === 'youtube'" viewBox="0 0 68 48" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
        fill="currentColor"
      />
      <path d="M 45,24 27,14 27,34" fill="#fff" />
    </svg>
    <svg v-if="$attrs.provider === 'vimeo'" viewBox="0 0 68 48" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 63 0 C 55.79 0.13 34 0 34 0 S 12.21 0.13 0 0 C 0.06 13.05 0 24 0 24 s 0.06 10.95 0 24 C 12.21 47.87 34 48 34 48 s 21.79 -0.13 34 -0 C 67.94 34.95 68 24 68 24 S 67.94 13.05 68 0 z"
        fill="currentColor"
      />
      <path d="M 45,24 27,14 27,34" fill="#fff" />
    </svg>
    <svg v-if="$attrs.provider === 'dailymotion'" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle r="12" cx="12" cy="12" stroke="none" fill="currentColor" opacity="0.5" />
      <path
        d="M8.56047 5.09337C8.34001 4.9668 8.07015 4.96875 7.85254 5.10019C7.63398 5.23162 7.5 5.47113 7.5 5.73011L7.5 18.2698C7.5 18.5298 7.63398 18.7693 7.85254 18.9007C7.96372 18.9669 8.0882 19 8.21268 19C8.33241 19 8.45309 18.9688 8.56047 18.9075L18.1351 12.6377C18.3603 12.5082 18.5 12.2648 18.5 12C18.5 11.7361 18.3603 11.4917 18.1351 11.3632L8.56047 5.09337Z"
        fill="#fff"
      />
    </svg>
  </span>
</template>
