<template>
  <AsfLink
    v-e2e="'ta-content-link'"
    :link="url"
    :target="target"
    :prevent="Boolean($attrs.prevent)"
    :is-anchor="isAnchor"
    :class="{ 'underline underline-offset-auto': isUnderlined }"
    v-bind="$attrs"
    class="asf-content-link"
  >
    <slot />
    <span :class="{ 'sr-only': isHideLabel }">{{ label }}</span>
  </AsfLink>
</template>

<script lang="ts" setup>
import type { ContentLinkProps } from '../types'

const props = withDefaults(defineProps<ContentLinkProps>(), {
  url: '',
  label: '',
  target: '_self'
})
const attrs = useAttrs()
const isAnchor = computed(() => props.url[0] === '#' || Boolean(attrs['is-anchor'] || attrs.isAnchor))
</script>
