import { type UseContent } from 'shared/types'
import { ContentClient, type DefaultContentBody } from 'dc-delivery-sdk-js'
import { type AmplienceContent } from '../types'

export const useContent: UseContent<AmplienceContent> = () => {
  const client = new ContentClient({
    hubName: 'astoundctsandbox',
    locale: 'en'
  })

  async function addContentHierarchyToContent<T extends AmplienceContent>(contentHierarchy: T) {
    if (!contentHierarchy.navigationBlockId) {
      return contentHierarchy
    }

    const { body: hierarchyContent } = await client.getContentItemByKey<T & DefaultContentBody>(
      contentHierarchy.navigationBlockId
    )

    const contents: T[] = []

    async function loadHierarchy(cursor?: string) {
      const res = await client
        .filterByContentType<T>('https://agsf.com/cms/schema/v1/content/blocks/navigation-item.json')
        .filterBy('/hierarchyGroupId', hierarchyContent.hierarchyGroupId)
        .page(12, cursor)
        .request({
          format: 'inlined',
          depth: 'all'
        })

      contents.push(...res.responses.map((value) => value.content))

      if (res.page.nextCursor) {
        await loadHierarchy(res.page.nextCursor)
      }
    }

    await loadHierarchy()

    hierarchyContent.children = contents

    if (process.server) {
      hierarchyContent._meta = hierarchyContent._meta.toJSON()
    }
    contentHierarchy.hierarchy = hierarchyContent

    return contentHierarchy
  }

  async function getContentMapByKeys<T extends AmplienceContent>(keys: string[]) {
    const { responses } = await client.getContentItemsByKey<T>(keys)
    const contents = await Promise.all(
      responses
        .flatMap((value) => {
          return 'content' in value ? value.content : []
        })
        .map(addContentHierarchyToContent)
    )

    return contents.reduce(
      (acc, item) => {
        return { ...acc, [item._meta?.deliveryKey as string]: [item] }
      },
      {} as { [key: string]: T[] }
    )
  }

  return {
    async searchContent({ keys }) {
      return await getContentMapByKeys<AmplienceContent>(keys)
    }
  }
}
