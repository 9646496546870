<template>
  <span class="inline-block" :style="styles">{{ text }}</span>
</template>

<script lang="ts" setup>
import type { ContentTextFieldProps } from '../types'
const props = defineProps<ContentTextFieldProps>()

const styles = computed(() => ({
  // an undefined value must be defined explicitly to avoid the case when the value is an empty string.
  // this is possible due to the way how Amplience is configured
  color: props.color || undefined,
  textAlign: props.alignment || undefined
}))
</script>
