import type { AmplienceImage, ContentBannerProps, ContentImageProps, ContentWrapperProps } from '../types'
import type { AsfBannerLink } from '@ui/types'

type ImageSrcsetExtended = {
  srcset: string
  media: string
  type?: string
}

const media = {
  sm: '(max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 1023px)',
  lg: '(min-width: 1024px) and (max-width: 1366px)'
}
const mediaXL = '(min-width: 1367px)'

const ctaClassNames = {
  primary: 'asf-button-primary',
  secondary: 'asf-button-secondary',
  accent: 'asf-button-accent',
  outline: 'asf-button-outline',
  simple: 'asf-button-simple'
}

const ctaSizeClassNames = {
  small: 'asf-button-small',
  regular: ''
}

const componentTypes = {
  'layout-100': 'grid grid-cols-1 grid-rows-1',
  'layout-50': 'grid grid-cols-1 grid-rows-2 gap-4 lg:grid-cols-2 lg:grid-rows-1',
  'layout-33': 'grid grid-cols-1 grid-rows-3 gap-4 lg:grid-cols-3 lg:grid-rows-1',
  'layout-25': 'grid grid-cols-2 grid-rows-2 gap-4 lg:grid-cols-4 lg:grid-rows-1',
  'layout-25-75': 'grid grid-cols-1 grid-rows-2 gap-4 lg:grid-cols-4 lg:grid-rows-1'
}

export const getImagePath = (src?: AmplienceImage, isLarge = false): string => {
  if (!src || !src.asset) {
    return ''
  }
  const { defaultHost, endpoint, name } = src.asset
  const path = `https://${defaultHost}/i/${endpoint}/${name}`
  const params = [`qlt=${src.quality || 'default'}`, `fmt=${src.format || 'auto'}`]

  if (src.width) {
    params.push(`w=${isLarge ? src.width * 2 : src.width}`)
  }

  if (src.height) {
    params.push(`h=${isLarge ? src.height * 2 : src.height}`)
  }

  if (isLarge) {
    return `${path}?${params.join('&')} 2x`
  }

  return `${path}?${params.join('&')}`
}

export const getImageSrcSet = (srcset: ContentImageProps['srcset'], src: AmplienceImage) => {
  if (!srcset) {
    return undefined
  }

  const imageSrcSet = [
    {
      srcset: `${getImagePath(src)}, ${getImagePath(src, true)}`,
      media: mediaXL
    }
  ]

  return (Object.entries(media) as [keyof typeof media, string][]).reduceRight(
    (prev: ImageSrcsetExtended[], [key, value]) => {
      if (!srcset[key]) {
        prev.push({
          srcset: prev.at(-1)?.srcset || '',
          media: value
        })

        return prev
      }

      prev.push({
        srcset: `${getImagePath(srcset[key]?.[0])}, ${getImagePath(srcset[key]?.[0], true)}`,
        media: value
      })

      return prev
    },
    imageSrcSet
  )
}

export const getCTALinks = (links: ContentBannerProps['links'] = []) => {
  if (Array.isArray(links)) {
    return links.map((link): AsfBannerLink => {
      const classNameArr = []
      link.size && ctaSizeClassNames[link.size] && classNameArr.push(ctaSizeClassNames[link.size])
      link.type && ctaClassNames[link.type] && classNameArr.push(ctaClassNames[link.type])

      return {
        link: link.url,
        label: link.label,
        target: link.target,
        className: classNameArr.join(' ')
      }
    })
  }

  return []
}

export const getWrapperType = (type: ContentWrapperProps['componentType']) => {
  if (!type) {
    return ''
  }

  return componentTypes[type]
}

export const getWrapperChildType = (
  children: ContentWrapperProps['children'],
  type: ContentWrapperProps['componentType'],
  isMirror: ContentWrapperProps['isMirror']
) => {
  return children.map((_, idx) => {
    if (type === 'layout-25-75') {
      switch (idx) {
        case 0:
          return !isMirror ? 'col-span-1' : 'col-span-3'
        case 1:
          return !isMirror ? 'col-span-3' : 'col-span-1'
        default:
          return ''
      }
    }

    return 'col-span-1'
  })
}
