<template>
  <button class="asf-slider__autoplay" aria-label="Stop automatic slide show.">
    <svg
      class="asf-slider__autoplay__svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle class="asf-slider__autoplay__progress-back" cx="18" cy="18" r="16.5" />
      <circle
        class="asf-slider__autoplay__progress"
        cx="18"
        cy="18"
        r="16.5"
        stroke-dasharray="104"
        transform="rotate(-90 18 18)"
      />
      <polygon class="asf-slider__autoplay__play" points="14.4,12.2 14.4,23 25.1,17.5 " />
      <polygon class="asf-slider__autoplay__pause" points="14.5 12 14.5 24" />
      <polygon class="asf-slider__autoplay__pause" points="21.5 12 21.5 24" />
    </svg>
  </button>
</template>
<script lang="ts">
export default defineComponent({
  name: 'AsfSliderPlayButton'
})
</script>
