<template>
  <div ref="sliderRef" tabindex="-1" class="asf-slider" :class="{ 'm-bullets': bulletsEnabled && slidesCount > 1 }">
    <div class="asf-slider__track" data-glide-el="track">
      <div class="asf-slider__slides">
        <slot />
      </div>
    </div>
    <div class="asf-slider__controls" data-glide-el="controls" v-if="controls && slidesCount > 1">
      <slot name="content-controls">
        <AsfButton
          ref="leftArrowRef"
          v-e2e="'left-arrow-control'"
          class="asf-slider__control m-left"
          :class="{ 'is-hidden': slider?.isMostLeft }"
          :disabled="slider?.isMostLeft"
          data-glide-dir="<"
          :aria-label="`${$t('slider.prev')}`"
        >
          <AsfIcon name="chevron-left" />
        </AsfButton>
        <AsfButton
          ref="rightArrowRef"
          v-e2e="'right-arrow-control'"
          class="asf-slider__control m-right"
          :class="{ 'is-hidden': slider?.isMostRight }"
          :disabled="slider?.isMostRight"
          data-glide-dir=">"
          :aria-label="`${$t('slider.next')}`"
        >
          <AsfIcon name="chevron-right" />
        </AsfButton>
      </slot>
    </div>
    <slot name="content-bullets">
      <AsfSliderBullets
        v-show="bulletsEnabled && slidesCount > 1"
        :current="active"
        :total="slidesCount"
        :options="bulletsOptions"
        @click:bullet="go(`=${$event}`)"
      >
        <template #autoplay-button>
          <AsfSliderPlayButton
            :class="{
              'm-paused': autoPlayState.isPaused || autoPlayState.isHovered,
              'm-animated': autoPlayState.isPaused || autoPlayState.isHovered || autoPlayState.isTimer
            }"
            :style="{ '--duration': `${options ? options.autoplay : 0}ms` }"
            @click="autoplayToggle"
          />
        </template>
      </AsfSliderBullets>
    </slot>
  </div>
</template>
<script lang="ts">
import type { AsfSliderOptions, AsfSliderProps, AsfSliderBullets as slBullets } from '@ui/types'
import { ArrowControls, Autoplay, FixBoundPeek, GlideExtended, SLIDER_CLASSES, SLIDER_EVENTS } from './Slider.utils'
import type { GlideConstructor, GlideModule } from '@glidejs/glide'
import type { AsfButton } from '#components'

export default defineComponent({
  name: 'AsfSlider',
  emits: [...SLIDER_EVENTS.map((name) => `slider:${name}`), 'update:active'],
  props: {
    active: { type: Number as PropType<AsfSliderProps['active']>, default: undefined },
    bullets: { type: [Boolean, Object] as PropType<AsfSliderProps['bullets']>, default: false },
    controls: { type: Boolean as PropType<AsfSliderProps['controls']>, default: true },
    count: { type: Number as PropType<AsfSliderProps['count']>, default: undefined },
    options: { type: Object as PropType<AsfSliderProps['options']>, default: () => ({}) }
  },
  setup(props: AsfSliderProps, { emit, slots }) {
    let Glide: typeof GlideConstructor | undefined
    const slider = ref<GlideModule | undefined>()
    const sliderRef = ref<HTMLElement>()
    const leftArrowRef = ref<InstanceType<typeof AsfButton> | null>(null)
    const rightArrowRef = ref<InstanceType<typeof AsfButton> | null>(null)
    const autoPlayState = reactive({
      isPaused: false,
      isHovered: false,
      isTimer: false
    })
    const defaultOptions = reactive<AsfSliderOptions>({
      classes: SLIDER_CLASSES,
      keyboard: false
    })
    const mergedOptions = computed<AsfSliderOptions>(() => ({
      ...defaultOptions,
      ...props.options
    }))
    const slidesCount = computed(() => {
      if (!slots.default) {
        return 0
      }

      if (props.count) {
        return Number(props.count)
      }

      return slots.default().flatMap((vNode) => Array.isArray(vNode.children) && vNode.children).length
    })
    const bulletsEnabled = computed(() => {
      if (typeof props.bullets !== 'boolean') {
        return props.bullets?.enable
      }

      return props.bullets
    })

    const isBulletObject = (bullets?: slBullets | boolean): bullets is slBullets => {
      return typeof bullets === 'object'
    }

    const bulletsOptions = computed<slBullets | null>(() => {
      if (isBulletObject(props.bullets)) {
        const isAutoPlayEnabled = Boolean(props.options?.autoplay)

        return {
          showPlayButton: props.bullets?.showPlayButton && isAutoPlayEnabled,
          position: props.bullets?.position || 'relative'
        }
      }

      return null
    })

    const go = (pattern: string) => {
      if (!slider.value) {
        return
      }

      return slider.value.go(pattern)
    }

    const changeSlide = () => {
      const active = props.active || 0
      if (active > slidesCount.value - 1) {
        return go(`=${slidesCount.value - 1}`)
      }

      if (active < 0) {
        return go('=0')
      }

      return go(`=${active}`)
    }

    const autoplayToggle = () => {
      if (!props.options?.autoplay || !slider.value) {
        return
      }

      if (slider.value.autoPlayPaused) {
        autoPlayState.isPaused = false
        slider.value.play()
      } else {
        autoPlayState.isPaused = true
        slider.value.pause()
      }
    }

    const initSlider = () => {
      const sliderRefValue = sliderRef.value
      if (!slidesCount.value || !Glide || !sliderRefValue) {
        return
      }

      const ArrowDisabler = ArrowControls(leftArrowRef.value, rightArrowRef.value)
      const { startAt } = mergedOptions.value
      const glide = new Glide(sliderRefValue, mergedOptions.value)
      const scrollSlides = glide.settings.slideToScroll || 1
      slider.value = glide.mutate([FixBoundPeek]).mount({ Autoplay, ArrowDisabler })
      SLIDER_EVENTS.forEach((name) => {
        glide.on(name, (event) => {
          emit(`slider:${name}`, event)
        })
      })
      glide.on('run.before', (move) => {
        if (move.direction === '>') {
          move.steps = -scrollSlides
        } else if (move.direction === '<') {
          move.steps = scrollSlides
        }
      })
      glide.on('move', () => {
        emit('update:active', slider.value?.index)
      })
      glide.on('track.hovered', (payload: { trackHovered: boolean }) => {
        autoPlayState.isHovered = payload.trackHovered
      })
      glide.on('toggle:timer', (payload: { isTimer: boolean }) => {
        autoPlayState.isTimer = payload.isTimer
      })
      if (!startAt) {
        changeSlide()
      }
    }

    watch(
      () => props.active,
      () => changeSlide()
    )

    onMounted(() => {
      nextTick(() => {
        import('@glidejs/glide').then((Module) => {
          Glide = GlideExtended(Module.default)
          initSlider()
        })
      })
    })

    onBeforeUnmount(() => {
      if (slider.value) {
        slider.value.destroy()
      }
    })

    return {
      sliderRef,
      leftArrowRef,
      rightArrowRef,
      slidesCount,
      bulletsEnabled,
      bulletsOptions,
      autoPlayState,
      slider,
      go,
      autoplayToggle
    }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Slider/Slider.css';
</style>
