<script setup lang="ts">
import type { ContentImageProps } from '../types'
import noImage from 'assets/images/noimage.png'

const props = withDefaults(defineProps<ContentImageProps>(), {
  title: '',
  isAdaptive: true,
  isHiddenMobile: false,
  isLazy: true,
  fetchPriority: 'low'
})

const imageSrc = computed(() => getImagePath(props.src))
const size = computed(() => ({
  width: (props.src && props.src.width) || '',
  height: (props.src && props.src.height) || ''
}))
const imageSrcSet = computed(() => getImageSrcSet(props.srcset, props.src))
</script>
<template>
  <picture
    class="asf-content-image"
    :class="{
      'is-adaptive': isAdaptive,
      'is-hidden-mobile': isHiddenMobile
    }"
  >
    <template v-if="imageSrcSet">
      <source
        v-for="srcItem in imageSrcSet"
        :key="srcItem.media"
        :srcset="srcItem.srcset"
        :media="srcItem.media"
        :type="srcItem.type"
      />
    </template>
    <img
      class="asf-content-image__node"
      :src="imageSrc || noImage"
      :sizes="sizes ? sizes : undefined"
      :alt="alt ? alt : undefined"
      :title="title ? title : undefined"
      :width="size.width ? size.width : undefined"
      :height="size.height ? size.height : undefined"
      :loading="isLazy ? 'lazy' : 'eager'"
      :fetchpriority="fetchPriority ? fetchPriority : undefined"
    />
  </picture>
</template>
<style lang="postcss">
@import '../styles/ContentImage.css';
</style>
