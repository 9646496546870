<template>
  <section class="asf-banner" :class="[contentAlignment, modifiers]" :style="styles">
    <figure class="asf-grid-layout asf-banner__container">
      <slot name="image" v-bind="{ className: imageClassName }">
        <AsfImage v-if="image && image.src" v-bind="image" class="asf-banner__image" />
      </slot>
      <figcaption class="asf-banner__caption">
        <AsfBannerCaption
          :links="links"
          :title="title"
          :subtitle="subtitle"
          :intro-title="introTitle"
          :description="description"
        >
          <template #intro-title="{ content }">
            <slot name="intro-title" :content="content" />
          </template>
          <template #title="{ content, className }">
            <slot name="title" :content="content" :class-name="className" />
          </template>
          <template #subtitle="{ content, className }">
            <slot name="subtitle" :content="content" :class-name="className" />
          </template>
          <template #description="{ content }">
            <slot name="description" :content="content" />
          </template>
        </AsfBannerCaption>
      </figcaption>
    </figure>
    <AsfLink
      v-if="link"
      :link="link.link"
      :target="link.target"
      :aria-label="`${link.label} ${title}`"
      class="asf-banner__link"
    >
      <span class="sr-only">{{ link.label }}</span>
    </AsfLink>
  </section>
</template>
<script lang="ts" setup>
import type { AsfBannerProps } from '@ui/types'

const props = withDefaults(defineProps<AsfBannerProps>(), {
  links: () => [],
  modifier: () => []
})
const styles = computed(() => ({
  '--banner-caption-align': props.alignment?.textAlignment || 'left'
}))
const contentAlignment = computed(() => {
  return [`m-x-axis-${props.alignment?.horizontal || 'left'}`, `m-y-axis-${props.alignment?.vertical || 'middle'}`]
})
const modifiers = computed(() => {
  if (!props.modifier) {
    return ''
  }

  const list: AsfBannerProps['modifier'][] = []

  if (Array.isArray(props.modifier)) {
    props.modifier.forEach((item) => list.push(`m-${item}`))
  } else {
    list.push(`m-${props.modifier}`)
  }

  return list
})
const imageClassName = 'asf-banner__image'
</script>
<style lang="postcss">
@import '@components/molecules/Banner/Banner.css';
</style>
