<script lang="ts" setup>
import type { ContentSlotProps } from 'shared/types'
import type { AmplienceContent } from '../types'
import {
  AccountPrivacyPolicyAgreement,
  ContentAccordion,
  ContentBanner,
  ContentCarousel,
  ContentHeading,
  ContentHTML,
  ContentImage,
  ContentLink,
  ContentNavigationGrid,
  ContentTextField,
  ContentVideo,
  ContentWrapper
} from '#components'

const componentsMap: Record<string, any> = {
  AccountPrivacyPolicyAgreement,
  ContentAccordion,
  ContentBanner,
  ContentCarousel,
  ContentHeading,
  ContentHTML,
  ContentImage,
  ContentLink,
  ContentNavigationGrid,
  ContentTextField,
  ContentVideo,
  ContentWrapper
}
defineProps<ContentSlotProps<AmplienceContent>>()
</script>

<template>
  <div class="asf-content-slot">
    <template v-for="(cmp, idx) in content" :key="idx">
      <component :is="componentsMap[cmp.componentName]" v-bind="cmp" />
    </template>
  </div>
</template>
