<script lang="ts" setup>
import type { ContentCarouselProps } from '../types'
import { ContentBanner, ContentImage } from '#components'

const componentsMap: Record<string, any> = { ContentBanner, ContentImage }

const props = defineProps<ContentCarouselProps>()

const activeIndex = ref<number>(props.options?.startAt || 0)
const attributes = computed(() => ({
  cmp: props.isFullWidth ? 'div' : 'asf-wrapper',
  sliderModifier: props.isFullWidth ? 'asf-slider--full-width' : '',
  contentModifier: props.isFullWidth ? 'm-full-width' : ''
}))
</script>
<template>
  <component :is="attributes.cmp" class="asf-content-carousel">
    <AsfSlider
      v-model:active="activeIndex"
      :options="options"
      :bullets="bullets"
      :controls="controls"
      :class="attributes.sliderModifier"
    >
      <AsfSliderItem v-for="(item, idx) in children" :key="`slide-${idx}`">
        <component :is="componentsMap[item.componentName]" v-bind="item" :class="attributes.contentModifier" />
      </AsfSliderItem>
    </AsfSlider>
  </component>
</template>
<style src="~/styles/ContentCarousel.css"></style>
