<script lang="ts" setup>
import type { ContentWrapperProps } from '../types'
import {
  AsfWrapper,
  ContentAccordion,
  ContentBanner,
  ContentHeading,
  ContentHTML,
  ContentImage,
  ContentNavigation,
  ContentNavigationGrid,
  ContentVideo
} from '#components'

const componentsMap: Record<string, any> = {
  ContentAccordion,
  ContentBanner,
  ContentHeading,
  ContentHTML,
  ContentImage,
  ContentNavigation,
  ContentNavigationGrid,
  ContentVideo
}

const props = defineProps<ContentWrapperProps>()

const attributes = computed(() => ({
  cmp: props.isFullWidth ? 'div' : AsfWrapper,
  wrapperTag: !props.isFullWidth ? 'section' : undefined,
  class: props.isFullWidth ? 'm-full-width' : ''
}))
const containerClassNames = computed(() => getWrapperType(props.componentType))
const elementClassNames = computed(() => getWrapperChildType(props.children, props.componentType, props.isMirror))
</script>
<template>
  <component :is="attributes.cmp" :tag="attributes.wrapperTag">
    <ContentHeading v-if="title && title.text" v-bind="title" />
    <div :class="containerClassNames">
      <template v-for="(item, idx) in children" :key="idx">
        <component
          :is="componentsMap[item.componentName]"
          :class="[elementClassNames[idx], attributes.class]"
          v-bind="item"
        />
      </template>
    </div>
  </component>
</template>
