<script setup lang="ts">
import type { ContentModalProps } from '../types'

const props = withDefaults(defineProps<ContentModalProps>(), { visible: false })
const emit = defineEmits(['close:modal'])

const contentHeight = ref(0)
const { searchContent } = useContent()
const { data: content, pending } = await useLazyAsyncData(
  async () => {
    return await searchContent({ keys: [props.id] })
  },
  { server: false }
)

const close = () => emit('close:modal', true)
const title = computed(() => content.value?.[props?.id]?.at(0)?.title)
const children = computed(() => content.value?.[props.id]?.at(0)?.children)

onMounted(() => {
  nextTick(() => {
    contentHeight.value = window.innerHeight - 190
  })
})
</script>
<template>
  <AsfModal
    v-if="!pending"
    class="asf-content-modal"
    appearance="md"
    :visible="!pending && visible"
    use-focus-trap
    aria-modal="true"
    role="dialog"
    @close="close"
  >
    <template v-if="title" #content-title="{ classNames }">
      <ContentHeading v-bind="title" :class="classNames" />
    </template>
    <div
      v-if="children"
      class="overflow-x-hidden overflow-y-auto scrollbar-light pr-3"
      :style="{ height: `${contentHeight}px` }"
    >
      <ContentSlot :content="children" class="asf-content" />
    </div>
  </AsfModal>
</template>
<style lang="postcss">
@import '../styles/ContentModal.css';
</style>
