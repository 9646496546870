type FocusItemCallback = (e: any) => void

export function useAccessibility<T>(items: Ref<readonly T[] | undefined>) {
  const focusableItems = items
  const currentElement = ref<T | undefined>(items.value?.[0])
  let currentlyFocused: T | undefined

  const firstElement = computed(() => items.value?.[0])
  const lastElement = computed(() => items.value?.[items.value?.length - 1])

  const getElementIndex = (element?: T) =>
    focusableItems && element ? focusableItems.value?.findIndex((item) => item === element) : -1
  const setFocusToPreviousItem = (setFocusToItem: FocusItemCallback) => {
    if (!currentlyFocused) {
      currentlyFocused = firstElement.value
    }

    currentlyFocused =
      currentlyFocused === firstElement.value
        ? lastElement.value
        : focusableItems.value?.[(getElementIndex(currentlyFocused) ?? 0) - 1]

    setFocusToItem(currentlyFocused)
  }
  const setFocusToNextItem = (setFocusToItem: FocusItemCallback) => {
    if (!currentlyFocused) {
      currentlyFocused = lastElement.value
    }

    currentlyFocused =
      currentlyFocused === lastElement.value
        ? firstElement.value
        : focusableItems.value?.[(getElementIndex(currentlyFocused) ?? 0) + 1]

    setFocusToItem(currentlyFocused)
  }
  const setFocusToFirstItem = (setFocusToItem: FocusItemCallback) => {
    currentlyFocused = firstElement.value
    setFocusToItem(firstElement.value)
  }
  const setFocusToLastItem = (setFocusToItem: FocusItemCallback) => {
    currentlyFocused = lastElement.value
    setFocusToItem(lastElement.value)
  }

  return {
    firstElement,
    lastElement,
    focusableItems,
    currentElement,
    setFocusToPreviousItem,
    setFocusToNextItem,
    setFocusToFirstItem,
    setFocusToLastItem
  }
}
