<template>
  <AsfHeading :tag="tag" :appearance="appearance" :styles="styles">
    <span>{{ text }}</span>
    <template v-if="$slots.default" #content-subtitle>
      <slot />
    </template>
  </AsfHeading>
</template>

<script lang="ts" setup>
import type { ContentHeadingProps } from '../types'
const props = withDefaults(defineProps<ContentHeadingProps>(), {
  tag: 'h1'
})

const styles = computed(() => ({
  // an undefined value must be defined explicitly to avoid the case when the value is an empty string.
  // this is possible due to the way how Amplience is configured
  color: props.color || undefined,
  textAlign: props.alignment || undefined
}))
</script>
