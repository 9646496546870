<template>
  <AsfBanner
    v-bind="content"
    :style="styles"
    class="js-promo-impression"
    :data-promo-id="deliveryKey"
    :data-promo-name="title && title.text"
    @click="() => reportPromoClick({ id: deliveryKey, name: (title && title.text) || _meta?.name || '' })"
  >
    <template #image="{ className }">
      <ContentImage v-if="image" v-bind="image" :class="className" />
    </template>
    <template #intro-title="{ content }">
      <ContentTextField :text="content" :color="introTitle?.color" :alignment="introTitle?.alignment" />
    </template>
    <template #title="{ content, className }">
      <ContentHeading
        :text="content"
        :alignment="title?.alignment"
        :appearance="title?.appearance"
        :class="className"
        :color="title?.color"
        :tag="title?.tag"
      />
    </template>
    <template #subtitle="{ content, className }">
      <ContentHeading
        :text="content"
        :alignment="subtitle?.alignment"
        :appearance="subtitle?.appearance"
        :class="className"
        :color="subtitle?.color"
        :tag="subtitle?.tag"
      />
    </template>
    <template #description="{ content }">
      <ContentTextField :text="content" :color="description?.color" :alignment="description?.alignment" />
    </template>
  </AsfBanner>
</template>

<script lang="ts" setup>
import type { AsfBannerLink, AsfBannerProps } from '@ui/types'
import type { ContentBannerProps } from '../types'
import { ContentHeading, ContentImage, ContentTextField } from '#components'

const props = defineProps<ContentBannerProps>()

const { reportPromoClick } = useTagManager()

const content: ComputedRef<AsfBannerProps> = computed(() => {
  let link: AsfBannerLink | null = null
  if (props.link?.url) {
    link = {
      link: props.link.url,
      label: props.link.label,
      target: props.link.target
    }
  }

  return {
    introTitle: props.introTitle?.text || '',
    title: props.title?.text || '',
    subtitle: props.subtitle?.text || '',
    description: props.description?.text || '',
    alignment: props.alignment,
    modifier: props.modifier,
    links: getCTALinks(props.links),
    link
  }
})

const image = computed(() => props.background?.image?.[0])

const styles = computed(() => ({
  backgroundColor: props.background?.color || 'transparent'
}))
const deliveryKey = computed(() => {
  return props._meta?.deliveryKey || props._meta?.deliveryId || ''
})
</script>
