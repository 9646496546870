// Use this file to define constants or utils data for the component
import type { AsfSliderOptions } from '@ui/types'
import type { ExtensionFunction, TransformerFunction } from '@glidejs/glide'
import Glide, { GlideConstructor, type GlideModule } from '@glidejs/glide'
import { AsfButton } from '#components'

type ArrowControl = InstanceType<typeof AsfButton> | null

export const SLIDER_CLASSES: AsfSliderOptions['classes'] = {
  arrow: {
    disabled: 'asf-slider__arrow--disabled'
  },
  direction: {
    ltr: 'asf-slider--ltr',
    rtl: 'asf-slider--rtl'
  },
  nav: {
    active: 'asf-slider__bullet--active'
  },
  slide: {
    active: 'asf-slider__slide--active',
    clone: 'asf-slider__slide--clone'
  },
  type: {
    carousel: 'asf-slider--carousel',
    slider: 'asf-slider--slider'
  },
  dragging: 'asf-slider--dragging',
  swipeable: 'asf-slider--swipeable'
}
export const SLIDER_EVENTS = [
  'build.before',
  'build.after',
  'mount.before',
  'mount.after',
  'move.after',
  'pause',
  'play',
  'run.after',
  'run.before',
  'run.end',
  'run.offset',
  'run.start',
  'update',
  'run',
  'move',
  'resize',
  'swipe.start',
  'swipe.move',
  'swipe.end',
  'translate.jump'
]

export const GlideExtended = (Module: typeof Glide): typeof GlideConstructor => {
  return class _Glide extends Module implements GlideModule {
    timer = false
    trackHovered = false
    autoPlayPaused = false
    isMostLeft = false
    isMostRight = false
  }
}

export const FixBoundPeek: TransformerFunction = (GlideCmp, Components) => ({
  modify(translate) {
    let isBound = Components.Run.isBound

    if (typeof isBound !== 'function') {
      isBound = () => {
        return GlideCmp.isType('slider') && GlideCmp.settings.focusAt !== 'center' && GlideCmp.settings.bound
      }
    }

    if (isBound() && Components.Run.isEnd()) {
      const peek = Components.Peek.value

      if (typeof peek === 'number') {
        return translate - peek
      }
      return translate - Number(peek.after)
    }

    return translate
  }
})
export const Autoplay: ExtensionFunction = (GlideCmp, Components, Events) => {
  let timer: ReturnType<typeof setInterval> | undefined
  let remainingTime: number | undefined
  let creationTime = 0

  const clearTimer = () => {
    clearInterval(timer)
    timer = undefined
    GlideCmp.timer = false
    Events.emit('toggle:timer', { isTimer: GlideCmp.timer })
  }
  const stop = () => {
    if (!timer) {
      return null
    }

    if ((GlideCmp.autoPlayPaused || GlideCmp.trackHovered) && remainingTime) {
      remainingTime -= Date.now() - creationTime
      return clearTimer()
    } else {
      remainingTime = undefined
      return clearTimer()
    }
  }
  const start = () => {
    const { autoplay } = GlideCmp.settings
    if (!autoplay || GlideCmp.autoPlayPaused) {
      return
    }

    creationTime = Date.now()
    if (remainingTime === undefined) {
      remainingTime = autoplay
    }

    if (!timer) {
      timer = setInterval(() => {
        Components.Run.make('>')
      }, remainingTime)
      GlideCmp.timer = Boolean(timer)
      Events.emit('toggle:timer', { isTimer: GlideCmp.timer })
    }
  }
  const autoPlayPause = () => {
    GlideCmp.trackHovered = true
    Events.emit('track.hovered', { trackHovered: GlideCmp.trackHovered })
    stop()
  }
  const autoPlayContinue = () => {
    GlideCmp.trackHovered = false
    Events.emit('track.hovered', { trackHovered: GlideCmp.trackHovered })
    start()
  }
  const bind = () => {
    Components.Html.track.addEventListener('mouseover', autoPlayPause)
    Components.Html.track.addEventListener('mouseleave', autoPlayContinue)
  }
  const unbind = () => {
    Components.Html.track.removeEventListener('mouseover', autoPlayPause)
    Components.Html.track.removeEventListener('mouseleave', autoPlayContinue)
  }
  const mount = () => {
    const { hoverpause } = GlideCmp.settings
    start()

    if (hoverpause) {
      bind()
    }
  }
  const addAccessibility = () => {
    const slides: HTMLElement[] | undefined = Components.Html.slides

    slides?.forEach((slide) => {
      slide.removeAttribute('inert')
    })

    const controls = Components.Html.root.querySelectorAll('button')
    controls.forEach((control) => {
      if (!control.classList.contains('asf-slider__autoplay')) {
        control.addEventListener('focus', autoPlayPause)
        control.addEventListener('blur', autoPlayContinue)
      }
    })
  }
  const removeAccessibility = () => {
    const slides: HTMLElement[] | undefined = Components.Html.slides

    slides?.forEach((slide) => {
      slide.removeEventListener('focus', autoPlayPause)
      slide.removeEventListener('blur', autoPlayContinue)

      slide.querySelectorAll('a').forEach((link) => {
        link.removeEventListener('focus', autoPlayPause)
        link.removeEventListener('blur', autoPlayContinue)
      })
    })

    const controls = Components.Html.root.querySelectorAll('button')
    controls.forEach((control) => {
      if (!control.classList.contains('asf-slider__autoplay')) {
        control.removeEventListener('focus', autoPlayPause)
        control.removeEventListener('blur', autoPlayContinue)
      }
    })
  }

  Events.on('pause', () => (GlideCmp.autoPlayPaused = true))
  Events.on('play', () => (GlideCmp.autoPlayPaused = false))
  Events.on(['destroy', 'update'], () => {
    removeAccessibility()
    unbind()
  })
  Events.on(['run.before', 'pause', 'destroy', 'swipe.start', 'update'], () => stop())
  Events.on(['run.after', 'play', 'swipe.end'], () => {
    start()
    addAccessibility()
  })
  Events.on('update', () => mount())

  return { mount }
}
export const ArrowControls = (leftArrow?: ArrowControl, rightArrow?: ArrowControl): ExtensionFunction => {
  return (GlideCmp, Components, Events) => ({
    mount() {
      if (GlideCmp.settings.rewind) {
        return
      }

      Events.on(['mount.after', 'run'], () => {
        if (leftArrow) {
          GlideCmp.isMostLeft = GlideCmp.index === 0
        }

        if (rightArrow) {
          GlideCmp.isMostRight = GlideCmp.index === Components.Sizes.length - GlideCmp.settings.perView
        }
      })
    }
  })
}
