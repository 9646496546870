<script setup lang="ts">
import type { AmplienceContent, ContentNavigationProps } from '../types'
const props = defineProps<ContentNavigationProps>()
const navigation = computed(() => {
  const result: AmplienceContent[] = []
  const { _meta } = props.hierarchy
  const rootDeliveryId = _meta?.deliveryId

  props.hierarchy.children?.forEach((item) => {
    if (item._meta?.hierarchy?.parentId === rootDeliveryId) {
      result.push({ ...item })
    } else {
      const firstLevelItem = result.find((i) => i._meta?.deliveryId === item._meta?.hierarchy?.parentId)
      if (firstLevelItem) {
        if (!firstLevelItem.children) {
          firstLevelItem.children = []
        }
        firstLevelItem.children.push({ ...item })
      }
    }
  })

  return result
})
</script>
<template>
  <div class="asf-content-nav">
    <ContentHeading v-bind="hierarchy.title" class="asf-content-nav__title" />
    <nav class="asf-content-nav__list">
      <AsfList class="asf-content-nav__items">
        <AsfListItem v-for="(item, index) in navigation" :key="item.link?.url || index" class="asf-content-nav__item">
          <ContentLink
            v-if="item?.link?.url"
            v-bind="item.link"
            :class="{ 'm-active': routePath.match(item.link.url) }"
            :prevent="Boolean(routePath.match(item.link.url))"
          />
          <AsfList
            v-if="item.children && item?.link?.url && routePath.match(item.link.url)"
            class="asf-content-nav__sublist"
          >
            <AsfListItem
              v-for="(childItem, childIndex) in item.children"
              :key="childItem.link?.url || childIndex"
              class="asf-content-nav__item"
            >
              <ContentLink
                v-if="childItem?.link?.url"
                v-bind="childItem.link"
                :class="{ 'm-active': routePath.match(childItem.link.url) }"
                :prevent="Boolean(routePath.match(childItem.link.url))"
              />
            </AsfListItem>
          </AsfList>
        </AsfListItem>
      </AsfList>
    </nav>
  </div>
</template>
<style src="~/styles/ContentNavigation.css"></style>
