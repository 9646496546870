<template>
  <div class="asf-video__preview" :class="[`m-${provider}`]" @click="$emit('click')">
    <template v-if="!isLoadedOnce">
      <template v-if="isVideoFound">
        <img
          v-if="thumbnail"
          class="asf-video__preview__image"
          :src="thumbnail"
          :alt="videoTitle"
          @error="handleImageLoad"
        />
        <img v-else class="asf-video__preview__image" :src="defaultThumbnail" :alt="videoTitle" />

        <span v-if="showTitle" class="asf-video__preview__title">{{ videoTitle }}</span>

        <button v-show="!isClicked" class="asf-video__preview__play" :aria-label="`${$t('video.play')}`">
          <slot name="button">
            <AsfVideoIconPlay :provider="provider" />
          </slot>
        </button>

        <div v-show="isClicked">
          <slot name="loader">
            <span class="asf-video__preview__loader">
              <span />
              <span />
              <span />
            </span>
          </slot>
        </div>
      </template>
      <template v-else-if="!isInfoFetched">
        <div class="asf-video__preview__error-container">
          <span class="asf-video__preview__error-icon">
            <AsfVideoIconError />
          </span>

          <p class="asf-video__preview__error-content">
            <span class="asf-video__preview__error-reason">{{ $t('error.video.reason') }}</span>
            <span>{{ $t('error.video.subReason') }}</span>
          </p>
        </div>
      </template>
    </template>
  </div>
</template>
<script lang="ts" setup>
import type { AsfVideoWrapper } from '@ui/types'
import { getDailyMotionThumbnail, getVimeoThumbnail, getYoutubeThumbnail } from '../Video.utils'
const props = withDefaults(defineProps<AsfVideoWrapper>(), {
  provider: 'youtube',
  showTitle: true,
  thumbnailQuality: 'standard',
  videoTitle: ''
})
const thumbnail = ref(props.customThumbnail)
const defaultThumbnail = computed(() => {
  if (props.provider === 'youtube') {
    return getYoutubeThumbnail(props.videoId, props.thumbnailQuality)
  }
  if (props.provider === 'vimeo') {
    return getVimeoThumbnail(props.videoId, props.thumbnailQuality)
  }

  if (props.provider === 'dailymotion') {
    return getDailyMotionThumbnail(props.videoId, props.thumbnailQuality)
  }

  return undefined
})

const handleImageLoad = () => {
  thumbnail.value = defaultThumbnail.value
}

watch(
  () => props.customThumbnail,
  (val, oldVal) => {
    if (val !== oldVal) {
      thumbnail.value = props.customThumbnail
    }
  }
)
</script>
